import React from 'react';
import css from './index.module.css';

import campaignMarketplace from '../../../../../assets/become-pro/campaign-marketplace.webp';
import arrow_right from '../../../../../assets/become-pro/arrow-right.svg';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import axiosInstance from '../../../../../axios';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import ModalV2Simple from '../../../../../components/ModalV2/ModalV2Simple';
import Loading from '../../../../../components/Loading/Loading';
import { camelToLabel } from '../../../../../util/string';
import { BlackButton } from '../../../../../components/Button/Button';

const CampaignMarketplace = () => {
  const history = useHistory();

  const HEAR_FROM = ['facebook', 'linkedin ', 'instagram', 'twitter', 'tiktok', 'others'];
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      hearFrom: '',
      instagram: '',
      tiktok: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string(),
      phone: Yup.string(),
      email: Yup.string().email('Invalid email address').required('Required'),
      hearFrom: Yup.string().required('Required'),
      instagram: Yup.string(),
      tiktok: Yup.string()
    }),
    onSubmit: async (values) => {
      if (formik.values.tiktok === '' && formik.values.instagram === '') {
        return;
      }
      await handleSendDemoRequest();
    }
  })

  const handleSendDemoRequest = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/slack/send-demo-creator-notification', formik.values);
      const { error, message } = response.data;

      if(error) {
        toast.error(message);
      } else {
        toast.success('Demo request sent successfully');
        setOpenModal(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.title}>Campaign Marketplace</div>
        <div className={css.description}>
          Connect with brands who are eager to collaborate with talented creators like you. From
          curated experiences to exclusive discounts, apply to opportunities that you resonate with.
        </div>
        <div className={css.btnContainer}>
          <button className={css.btn_join} onClick={() => history.push('/login')}>
            Join now
            <img src={arrow_right} alt="right arrow" />
          </button>
          <button className={css.btnWhiteOutline} onClick={() => setOpenModal(true)}>
            Book a demo
          </button>
        </div>
      </div>
      <div className={css.imageContainer}>
        <img className={css.image} src={campaignMarketplace} alt="campaign-marketplace" />
      </div>
      <ModalV2Simple
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title="Book a Demo"
        width={500}
      >
        <div className={css.formContainer}>
          <div className={css.grid_2}>
            <div>
              <div>First Name</div>
              <input type="text" name={"firstName"} value={formik.values.firstName} onChange={formik.handleChange} placeholder="First Name" className={css.inputText} />
              <div className={css.errorMessage}>{formik.errors.firstName}</div>
            </div>
            <div>
              <div>Last Name</div>
              <input type="text" name={"lastName"} value={formik.values.lastName} onChange={formik.handleChange} placeholder="Last Name" className={css.inputText} />
              <div className={css.errorMessage}>{formik.errors.lastName}</div>
            </div>
            <div>
              <div>Phone (Optional)</div>
              <input type="text" name={"phone"} value={formik.values.phone} onChange={formik.handleChange} placeholder="Phone" className={css.inputText} />
              <div className={css.errorMessage}>{formik.errors.phone}</div>
            </div>
            <div>
              <div>Email</div>
              <input type="text" name={"email"} value={formik.values.email} onChange={formik.handleChange} placeholder="Email" className={css.inputText} />
              <div className={css.errorMessage}>{formik.errors.email}</div>
            </div>
          </div>
          <div>
            <div>How did you hear about Collabreate</div>
            <select className={css.inputSelect} name='hearFrom' value={formik.values.hearFrom} onChange={formik.handleChange}>
              <option value={""} selected disabled>Select</option>
              {
                HEAR_FROM.map((item, index) => (
                  <option key={index} value={item}>{camelToLabel(item)}</option>
                ))
              }
            </select>
            <div className={css.errorMessage}>{formik.errors.hearFrom}</div>
          </div>
          <div>
            <div className={css.grid_2}>
              <div>
                <div>Tiktok</div>
                <input type='text' name='tiktok' onChange={formik.handleChange} value={formik.values.tiktok} className={css.inputText} />
              </div>
              <div>
                <div>Instagram</div>
                <input type='text' name='instagram' onChange={formik.handleChange} value={formik.values.instagram} className={css.inputText} />
              </div>
            </div>
            <div className={css.errorMessage}>{formik.values.tiktok === '' && formik.values.instagram === '' && "Either Instagram / Tiktok must be provided."}</div>
          </div>
          <div>
            <BlackButton onClick={formik.handleSubmit}>
              <div className={css.buttonTextWithLoading}>
                Submit
                {loading && <Loading />}
              </div>
            </BlackButton>
          </div>
        </div>
      </ModalV2Simple>
    </div>
  );
};

export default CampaignMarketplace;
